import * as React from "react"
import {
  Box,
  Center,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";

const LegalDoc = ( {children} ) => {
  return (
    <Center py={6}>
      <Box
        w={'100%'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        paddingBottom={2}
        overflow={'hidden'}
      >
        <Container maxW="container.md" paddingTop="1vw" paddingBottom="2vw">
          {children}
        </Container>
      </Box>
    </Center>
  )
}

export default LegalDoc;
import * as React from 'react'
import Helmet from 'react-helmet'
import { Center, Link, Text, chakra, Button, Box } from '@chakra-ui/react'

import Layout from '../components/layout'
import LegalDoc from '../components/LegalDoc'

const LegalNoticePage = () => {
  const siteTitle = 'Impressum'
  const siteDescription = 'Impressum Marcel Hurler'

  return (
    <>
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="Impressum">

            <Box w="100%" p={0}>
              <Link href="/">
                <Button>Home</Button>
              </Link>
            </Box>
            <div id="impressum">
            <LegalDoc>
              <Text className="hyphenate">
                <Center>
                  <chakra.h1 fontSize="4xl" id="Impressum-Impressum">
                    <span>Impressum</span>
                  </chakra.h1>
                </Center>
                <br></br>
                <br></br>
                <chakra.h2 fontSize="3xl" id="Impressum-Urheberrecht">
                  <span>Urheberrecht</span>
                </chakra.h2>
                <br></br>
                <p>
                  Diese Internetseite ist urheberrechtlich geschützt. Alle
                  Rechte vorbehalten. Die Verwendung der Texte und Abbildungen,
                  auch auszugsweise, ist ohne unsere schriftliche Zustimmung
                  urheberrechtswidrig und daher strafbar. Dies gilt insbesondere
                  für die Vervielfältigung, Übersetzung und die Verwendung in
                  allen Medien gleich welcher Form. Alle Informationen auf
                  diesen Seiten wurden mit größter Sorgfalt kontrolliert.
                  scitlab kann nicht für Schäden haftbar gemacht werden, die in
                  Zusammenhang mit der Verwendung dieser Inhalte stehen.
                </p>
                <br></br>
                <chakra.h2 fontSize="3xl" id="Impressum-Haftung">
                  <span>Haftung</span>
                </chakra.h2>
                <br></br>
                <p>
                  Wir weisen ausdrücklich darauf hin, dass alle Angaben auf
                  dieser Webseite keine Zusicherung oder Garantie darstellen.
                  Jegliche Haftung aufgrund von fehlerhaften, unvollständigen
                  oder veralteten Informationen wird ausgeschlossen.
                </p>
                <br></br>
                <chakra.h2 fontSize="3xl" id="Impressum-Kontakt">
                  Kontakt
                </chakra.h2>
                <br></br>
                <p>
                  <span>
                    <strong>Marcel Hurler</strong>
                  </span>
                  <br />
                  <span>Bosslerstraße 14</span>
                  <br />
                  <span>73278 Schlierbach</span>
                  <br />
                  <span>‍</span>
                  <br />
                  <span>
                    <strong>Kontakt</strong>
                  </span>
                  <br />
                  <span>
                    <span>E-Mail: </span>
                    <Link
                      href="mailto:hurlerml@gmail.de"
                      rel="nofollow"
                      class="external-link"
                    >
                      hurlerml@gmail.com
                    </Link>
                    <span> ‍</span>
                  </span>
                </p>
              </Text>
            </LegalDoc>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default LegalNoticePage
